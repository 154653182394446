/* eslint-disable */
import HttpService from '../HttpService';
export default class EuProtegidoService {
  constructor() {/* eslint-disable */
    this._httpService = new HttpService(`security`);
  }

  FindAllPhonesByUserId() {
    return this._httpService.get('/phone/find_all_phones_by_user_id');
  }

  Save(phone) {
    return this._httpService.post('/phone', phone);
  }

  Update(phone) {
    return this._httpService.put('/phone/' + phone.id, phone);
  }

  ValidatePhoneById(phoneId) {
    return this._httpService.get('/phone/validate_phone_by_id/' + phoneId);
  }

  VerifyValidationCodeIsValid(validateionCode, phoneId) {
    return this._httpService.get('/phone/verify_validation_code_is_valid/' + validateionCode + '/' + phoneId);
  }
}

